
// TODO: mixin imports, especially directional
/*

$breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
 */

.form-wrapper {
  position: relative;
}

.register-component {
  max-width: calc(90vw - 60px);
  width: 550px;
  flex: 550px;

  @media screen and (max-width: 640px) {
    max-width: calc(90vw - 60px);
    width: 80vw;
    flex: 80vw;
  }

  @media screen and (max-width: 400px) {
    max-width: calc(90vw - 20px);
    width: 90vw;
    flex: 90vw;
  }

  .uk-card-body {
    padding-top: 0;
    @media screen and (max-width: 400px) {
      padding: 20px 10px;
    }
  }

  .form-footer-links {
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    .link-wrap {
      width: 100%;
      flex: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
